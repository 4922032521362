<template>
    <div class="w-80 flex-shrink-0 px-4 pt-4 flex flex-col max-h-full">
        <div class="flex items-center space-x-4 mb-4">
            <div class="text-md font-semibold text-gray-600 flex flex-col gap-4">
                Clients
                <Input
                    class="search-input w-full"
                    icon="search"
                    v-model="searchString"
                    placeholder="Search client"
                />
            </div>
        </div>

        <div class="w-full max-h-full overflow-y-auto scroll-bar h-full mt-3">
            <template v-if="loading">
                <div class="flex w-full">
                    <Loader :size="8" class="mx-auto my-5" />
                </div>
            </template>
            <template v-else>
                <div v-allow:auth="'client.readList'">
                    <template v-if="filteredClients?.length">
                        <ClientCard
                            v-for="client in filteredClients"
                            :key="client.id"
                            :client="client"
                            :progress="true"
                            :selected="selected?.id === client.id"
                            @click="selectClient(client)"
                        />
                    </template>
                    <template v-else>
                        <div class="col-span-4">
                            <AlertInfo type="info" message="No Clients Founds!" />
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Input from "@/components/input";
import AlertInfo from "@/components/alert-box";
import Loader from "@/components/loader";
import ClientCard from "@/components/client-card";

export default {
    components: {
        Input,
        AlertInfo,
        Loader,
        ClientCard,
    },
    props: {
        loading: Boolean,
        list: {
            type: Array,
            required: true,
        },
        selected: Object,
    },
    data() {
        return {
            searchString: "",
        }
    },
    computed: {
        filteredClients() {
            return this.list.filter((cli) => cli.name.toLowerCase().includes(this.searchString.toLowerCase()));
        },
    },
    methods: {
        selectClient(payload) {
            this.$emit('select', payload);
        },
    },
}
</script>

<style lang="scss" scoped>
.search-input {
    ::v-deep {
        input {
            width: 100%;
        }
    }
}
</style>